<template>
	<div data-route>
		<page-header
			heading="Checking payment status"
		/>
		<div data-element="main">
			<p>Waiting…</p>
			<btn
				size="xl"
				route="/browse-courses"
				text="Cancel"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import Btn         from '@/components/ui/Btn';
	import api         from '@/services/api';

	export default {
		metaInfo: {
			title: 'Checking payment status'
		},
		components: {
			PageHeader,
			Btn
		},
		data: () => ({
			enrolment: undefined,
			timer: undefined
		}),
		computed: {
			getEnrolmentId () {
				return this.$route.query.id;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					},
					{
						path: '/browse-courses/checkout-complete',
						text: 'Checking payment status'
					}
				].filter(Boolean);
			}
		},
		watch: {
			enrolment (newVal) {
				console.log('Enrolment changed');
				if (!newVal?.activated) {
					console.log('Enrolment not activated yet though');
					return false;
				}
				console.log('Enrolment activated, stopping timer');
				clearTimeout(this.timer);
				this.onActivated();
			}
		},
		created () {
			this.init();
		},
		beforeDestroy () {
			if (!this.timer) {
				return;
			}
			clearTimeout(this.timer);
		},
		methods: {
			async runTimer () {
				const app = this;
				const enrolment = await api.courses.getEnrolmentById({
					enrolmentId: this.getEnrolmentId
				});
				if (enrolment?.activated) {
					this.enrolment = enrolment;
					return true;
				}
				console.log(enrolment);
				this.timer = setTimeout(() => {
					console.log('Running timer again');
					app.runTimer();
				}, 3000);
			},
			async init () {
				if (!this.getEnrolmentId) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
				await this.runTimer();
			},
			async onActivated () {
				await this.$router.push('/my-courses');
				this.$store.commit('ui/showNotification', {
					notification: 'Your course has been added'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {
		[data-element='main'] {
			p {
				font-size:rem(18);
				margin:0 0 rem(32);
			}
			[data-component='btn'] {
				float:left;
				width:auto;
				margin:0 auto 0 0;
			}
		}
	}
</style>
